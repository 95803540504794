import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Loader } from '@components/shared/Loader/Loader';
import NotFound from '@components/shared/NotFound/NotFound';
import { useInjection } from '@context/inversify-context-provider';
import { OODocumentProvider } from '@features/oneOnboarding/context/DocumentContext';
import { AdminRoutes, ClientRoutes, OneOnboardingRoutes, RouteRegistration } from '@features/routes/routes';
import {
  getClientOrganizationId,
  getClientOrganizationIdImport,
  getSelectedConfigurationId,
  setRedirectionUrl,
} from '@helpers/tenant.helper';
import { useGetOnboardingTheme } from '@hooks/apiHooks';
import { useLocationQuery } from '@hooks/useLocationQuery';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { RootState } from '@store/rootReducer';
import { authAdminSlice } from '@store/slices/authAdmin.slice';
import {
  oneOnboardingSlice,
  setApplicationId,
  setClientOrganizationId,
  setClientOrganizationIdImport,
  setUserId,
} from '@store/slices/oneOnboarding.slice';
import { defaultTheme } from '@styles/defaultTheme';
import { theme2 } from '@styles/mui-theme2';
import { theme3 } from '@styles/mui-theme3';
import { createOnboardingTheme } from '@styles/ootheme';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import '@mui/styles';

import Snackbar from '../src/components/shared/Snackbar/Snackbar';
import IdleTimerContainer from './components/shared/idleTimer/IdleTimerContainer';
import { AamBackendApi } from './libs/aamBackendApi';
import { store } from './store';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const RedirectToAppFromRoot: React.FC = () => {
  const history = useHistory();
  const location = useLocationQuery();
  const tenant = useSelector((state: RootState) => state.tenant);
  const campaignUrl = location.get('campaignUrl');
  useEffect(() => {
    if (history.location.pathname === '/' && campaignUrl === null) {
      history.replace(tenant.useOneOnboarding ? '/oo' : '/app');
    }
  }, [history, tenant, campaignUrl]);

  return <></>;
};

export const PrivateRoute = ({ component, isAuthenticated, redirectTo, sideMenu, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isAuthenticated ? (
      React.isValidElement(component) ? (
        component
      ) : (
        React.createElement(component, props)
      )
    ) : (
      <Redirect to={{ pathname: redirectTo }} />
    );

  return <Route {...rest} component={routeComponent} />;
};

const App: React.FC = () => {
  const aamBackendApi = useInjection(AamBackendApi);
  const tokenApp = useSelector((state: RootState) => state.authApp.idToken);
  const authMethodApp = useSelector((state: RootState) => state.authApp.authMethod);
  const tokenAdmin = useSelector((state: RootState) => state.authAdmin.idToken);
  const authMethodAdmin = useSelector((state: RootState) => state.authAdmin.authMethod);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { search = {}, pathname = '' } = document.location;
  const [onboardingTheme, setOnboardingTheme] = useState<Partial<ThemeOptions> | undefined>(undefined);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { data } = useGetOnboardingTheme(getClientOrganizationId(), getSelectedConfigurationId());
  const [coId, setCoId] = useState<string | undefined>();
  const [scId, setScId] = useState<string | undefined>();

  useEffect(() => {
    if (data) {
      if (data?.additionalData?.favicon) {
        const favicon: any = document.getElementById('favicon');
        favicon.href = `${process.env.PUBLIC_URL}/${data.additionalData.favicon}`;
      }
      if (data?.additionalData?.title) {
        const title: any = document.getElementById('title');
        title.textContent = data.additionalData.title;
      }
      setOnboardingTheme(data?.theme?.themeConfig || {});
    }
  }, [data]);
  useQuery({
    queryKey: ['getAdminUser', tenantId],
    queryFn: () => aamBackendApi.fetchUserByAdIdWithUserProfile(tenantId, store.getState().authAdmin.adeccoAdId as any),
    enabled: false,
    onSuccess: (user) => {
      if (user) {
        const userProfile: any = { ...(user?.userProfile || {}) };
        dispatch(authAdminSlice.actions.userData({ user: { ...user, userProfile: undefined }, userProfile }));
      }
    },
    onError: (err: unknown) => {
      const { data, status } = (err as any).response || {};
      if (data?.error?.name === 'AuthTokenUserMissingError' && status === 400) {
        dispatch(authAdminSlice.actions.clear(true));
      }
    },
  });

  useEffect(() => {
    let clientOrganizationId = getClientOrganizationId();
    const clientOrganizationIdImport = getClientOrganizationIdImport();

    const isRecruiterApp = Boolean(tokenAdmin) && authMethodAdmin === 'adeccoAD';
    if (isRecruiterApp) {
      queryClient.refetchQueries(['getAdminUser']);
    }

    const clientOrganizationFromParam = new URLSearchParams(search).get('clientOrganizationId');
    if (
      !clientOrganizationId ||
      (!!clientOrganizationFromParam && clientOrganizationFromParam !== clientOrganizationId)
    ) {
      clientOrganizationId = clientOrganizationFromParam;
    }
    const userIdParam = new URLSearchParams(search).get('candidateId');
    store.dispatch(setClientOrganizationId(clientOrganizationId));
    store.dispatch(setClientOrganizationIdImport(clientOrganizationFromParam ?? clientOrganizationIdImport));
    store.dispatch(setUserId(userIdParam));

    const applicationIdFromParam = decodeURIComponent(new URLSearchParams(search).get('applicationId') || '');
    let applicationId = localStorage.getItem('applicationId');

    if (!applicationId || (!!applicationIdFromParam && applicationIdFromParam !== applicationId)) {
      applicationId = applicationIdFromParam;
    }

    store.dispatch(setApplicationId(applicationId));

    let selectedConfigurationId = getSelectedConfigurationId();
    const selectedConfigurationFromParam = new URLSearchParams(search).get('selectedConfigurationId');
    if (
      !selectedConfigurationId ||
      (!!selectedConfigurationFromParam && selectedConfigurationFromParam !== selectedConfigurationId)
    ) {
      selectedConfigurationId = selectedConfigurationFromParam;
    }
    store.dispatch(oneOnboardingSlice.actions.setConfigurationId(selectedConfigurationId));

    const isFlowPath = pathname?.includes('/oo/flow/');
    const isRecruiterPath = pathname?.includes('/oo/recruiter/') && pathname !== '/oo/recruiter/tenant';

    const shouldRedirectFlow = isFlowPath && (!tokenApp || authMethodApp !== 'b2c');
    const shouldRedirectRecruiter =
      isRecruiterPath && !tokenAdmin && (authMethodApp === 'b2c' || authMethodApp === null);

    if (shouldRedirectFlow || shouldRedirectRecruiter) {
      setRedirectionUrl(pathname);
    }
    if (clientOrganizationId && clientOrganizationId !== coId) {
      setCoId(clientOrganizationId);
    }
    if (selectedConfigurationId && selectedConfigurationId !== scId) {
      setScId(selectedConfigurationId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pathname, tenantId]);

  useEffect(() => {
    let isMounted = true;
    const asyncCall = async () => {
      const res = await aamBackendApi.getOnboardingTheme(coId as any, scId as any);
      if (isMounted) {
        setOnboardingTheme(res?.theme?.themeConfig || {});
        const additionalData = res?.additionalData;
        const favicon: any = document.getElementById('favicon');
        const title: any = document.getElementById('title');
        if (additionalData?.favicon) {
          favicon.href = `${process.env.PUBLIC_URL}/${additionalData.favicon}`;
        }
        if (additionalData?.title) {
          title.textContent = additionalData.title;
        }
      }
    };
    if (tenantId && coId && scId) {
      asyncCall();
    } else {
      setOnboardingTheme(defaultTheme);
    }
    return () => {
      isMounted = false;
    };
  }, [tenantId, coId, scId, aamBackendApi]);

  if (!onboardingTheme) {
    return <Loader />;
  }

  const getRoutes = (routeRegistrations: RouteRegistration[]) => {
    const urlSearchParams = new URLSearchParams(search);
    const clientOrganizationId =
      urlSearchParams.get('clientOrganizationId') || localStorage.getItem('clientOrganizationId');
    const tenantId = urlSearchParams.get('tenantId') || localStorage.getItem('tenantId');
    const adminRedirectUrl = '/oo/recruiter/login';
    const candidateRedirectUrl = clientOrganizationId && tenantId ? '/app/login' : '/app/tenant';

    return (
      <Switch>
        {routeRegistrations.map((x) => {
          if (x.auth.requiresAdeccoAdToken) {
            const isRoleIncluded =
              x.allowedRoles && store.getState().authAdmin?.user
                ? x.allowedRoles?.includes(store.getState().authAdmin?.user?.role as any)
                : true;

            return (
              <PrivateRoute
                key={x.path}
                exact={x.isExact}
                path={x.path}
                component={x.component}
                isAuthenticated={Boolean(tokenAdmin) && authMethodAdmin === 'adeccoAD' && isRoleIncluded}
                redirectTo={adminRedirectUrl}
              />
            );
          } else if (x.auth.requiresB2CToken) {
            return (
              <PrivateRoute
                key={x.path}
                exact={x.isExact}
                path={x.path}
                component={x.component}
                isAuthenticated={Boolean(tokenApp) && authMethodApp === 'b2c'}
                redirectTo={candidateRedirectUrl}
              />
            );
          }

          return <Route key={x.path} exact={x.isExact} path={x.path} component={x.component} />;
        })}
        <Route path="*" component={NotFound} />
      </Switch>
    );
  };

  return (
    <OODocumentProvider>
      <>
        <Snackbar />
        <Router>
          <RedirectToAppFromRoot />
          {/* App */}
          <Switch>
            <Route path={OneOnboardingRoutes.map((x) => x.path)}>
              <IdleTimerContainer timeout={20} />
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={createOnboardingTheme(onboardingTheme)}>
                  <main className="app">{getRoutes(OneOnboardingRoutes)}</main>
                </ThemeProvider>
              </StyledEngineProvider>
            </Route>
            {/* Admin */}
            <Route path={AdminRoutes.map((x) => x.path)}>
              <IdleTimerContainer timeout={60} />
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme3}>
                  <main>
                    <div className="admin">{getRoutes(AdminRoutes)}</div>
                  </main>
                </ThemeProvider>
              </StyledEngineProvider>
            </Route>
            {/* Client dashboard */}
            <Route path={ClientRoutes.map((x) => x.path)}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme2}>
                  <main>
                    <div className="client-drawer" />
                    <div className="client">{getRoutes(ClientRoutes)}</div>
                  </main>
                </ThemeProvider>
              </StyledEngineProvider>
            </Route>
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </>
    </OODocumentProvider>
  );
};

export default App;
